<template lang="html">
    <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuCM" role="button"
           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{$t('element_header.contentMg')}}
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuCM">
            <a class="dropdown-item" :href='getAbsoluteAction("SearchMaterial", "Material")'> {{$t('element_header.textbookSearch')}}</a>
            <a class="dropdown-item" :href='getAbsoluteAction("ManageImport", "Video")'>{{$t('element_header.videoImport')}}</a>
            <a class="dropdown-item" :href='getAbsoluteAction("RequestMgmt", "Admin")'>{{$t('element_header.translationMg')}}</a>
            <a class="dropdown-item" :href='getAbsoluteAction("LexMgmt", "Admin")'>{{$t('element_header.vocabularyMg')}}</a>
            <a class="dropdown-item" :href='getAbsoluteAction("TagMgmt", "Admin")'>{{$t('element_header.ThemeLabelMg')}}</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" :href='getAbsoluteAction("List", "Material")'>{{$t('element_header.textBookListOld')}}</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="https://youtu.be/iitrHMHBGsM" target="_blank">{{$t('element_header.videoImportSample')}}</a>
        </div>
    </li>
</template>
    
<script>
    import { ghelper } from '@/lib/ghelper';

    export default {
        components: {
        },
        props: {
        },
        data: function() {
            return {
            };
        },
        methods: {
            getAbsoluteAction: function (action, ctrl) {
                return ghelper.getActionUrl(action, ctrl);
            },
        },
        mounted: function () {
        },
        computed: {
        },
    };
</script>
    
<style lang="css">
</style>